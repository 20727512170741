import { createApi } from './baseApi'
import Cookies from 'universal-cookie'
import Notify from 'devextreme/ui/notify'

const cookies = new Cookies()

function showNotify(type, message, refresh_page = true) {
  alert(message)
  // Notify(
  //   {
  //     message: message,
  //     height: 55,
  //     width: 'auto',
  //     minWidth: 150,
  //     type: type, // ['error', 'info', 'success', 'warning']
  //     displayTime: type === 'error' ? 8000 : 2500,
  //     animation: {
  //       show: { type: 'fade', duration: 1000, from: 0, to: 1 },
  //       hide: { type: 'fade', duration: 1000, from: 1, to: 0 },
  //     },
  //   },
  //   {
  //     position: 'bottom center',
  //     direction: 'up-push',
  //   },
  // )
  if (type === 'success' && refresh_page) {
    setTimeout(() => {
      window.location.reload()
    }, 2500)
  }
}

export const BaseURL = process.env.REACT_APP_API_URL
export class ProductApiService {
  constructor() {
    this.ApiService = createApi()
  }
  post_appointments = async (params) => {
    let response = await fetch(
      BaseURL + '/strategies/algoritmic/create_appointments/',

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
      },
    )
    let result = await response.json()
    if (response?.status === 201) {
      return true
    }
    showNotify('error', `Create Appointments Error: ${result['error']}`, false)
    return false
  }

  get_algoritmic_strategies = async (params) => {
    let response = await fetch(
      BaseURL + '/strategies/algoritmic/algorithmic_strategy/',

      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
      },
    )
    let result = await response.json()
    if (response?.status === 200) {
      return result
    }
    showNotify('error', `GET Algoritmic Strategies Error: ${result['error']}`, false)
    return []
  }

  get_videos = async () => {
    const res = await this.ApiService.get(`/strategies/algoritmic/get_videos_web/`)
    if (res?.status === 200) {
      return res.data
    }
    showNotify('error', `Get Videos Error: ${res['error']}`, false)
    return null
  }
}
