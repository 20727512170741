import React from 'react'

function Top() {
  return (
    <div></div>
    // <div className="container-fluid text-center py-3 bg-primary text-white">
    //   <img src="./assets/image/play.svg" alt="" className="me-3" />
    //   <span className="fw-bold">Get a Free Trail </span>
    //   Start your <span className="fw-bold">14-day</span> trial period to use Sovrun strategy. ⚙️
    // </div>
  )
}

export default Top
