import { useState } from 'react'
import { current_backend_link } from '../utils/common'
import Modal from 'react-bootstrap/Modal'
import ReactPlayer from 'react-player/lazy'
import CloseButton from 'react-bootstrap/CloseButton'

function VideosSlide(props) {
  const [video_link, setVideoLink] = useState()
  const [show_modal, setShowModal] = useState(false)
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const show_video_modal = (video) => {
    setVideoLink(video)
    setShowModal(true)
  }
  return (
    <>
      <div className="row py-5">
        <div className="col-12 d-flex justify-content-between mb-4">
          <div className="title text-white fs-4">Videos</div>
          <div>
            <a href="/videos">All Videos</a>
          </div>
        </div>
        {props.videos.map((video, index) => {
          return (
            <>
              <div key={index} className="col-12 col-md-4">
                <div className="video-image mb-4">
                  <img
                    height={374}
                    width={205}
                    key={index + 100}
                    src={current_backend_link + video.thumbnail}
                    className="play-btn img-fluid"
                    alt=""
                    onClick={(e) => show_video_modal(video.link)}
                  />
                </div>
                <div key={index + 1000} className="video-text">
                  <p className="text-white">{video.description}</p>
                </div>
              </div>
            </>
          )
        })}
      </div>
      <Modal show={show_modal} onHide={handleCloseModal} id="videoplayer" centered size="xl">
        <Modal.Header>
          <CloseButton onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={current_backend_link + video_link}
            width={1118}
            height={950}
            controls={true}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VideosSlide
