import React from 'react'
import Strategies from '../components/Strategies'
import VideosSlide from '../components/VideosSlide'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { ProductApiService } from '../utils/productApiService'
import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactPlayer from 'react-player/lazy'
import CloseButton from 'react-bootstrap/CloseButton'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  const [videos, setVideos] = useState()
  const [show_modal, setShowModal] = useState(false)
  const [show_sub_modal, setShowSubModal] = useState(false)
  const [show_demo_modal, setShowDemoModal] = useState(false)
  const [count, setCount] = useState(0)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleCloseSubModal = () => {
    setShowSubModal(false)
  }
  const handleCloseDemoModal = () => {
    setShowDemoModal(false)
  }
  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_videos().then((response) => {
      setVideos(response)
    })
  }, [])
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      let dynamic_text_data = [
        'Be SOVRUN over your investments',
        '1.Learn from an institutional perspective',
        '2.Analyze and allocate like a pro',
        '3.Automate with our AI',
        '4.Grow your investments.',
      ]
      let dynamic_text = document.getElementById('dynamic-text')
      dynamic_text.innerHTML = dynamic_text_data[count]
      if (count < dynamic_text_data.length - 1) {
        setCount(count + 1)
      } else {
        setCount(0)
      }
    }, 2000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [count])

  return (
    <div>
      <Header />
      <div className="hero container-fluid">
        {/* Jumbutrons */}
        <div className=" hero-banner">
          <video autoPlay={true} muted={true} loop={true}>
            <source src={'../assets/image/sovrun-header-video.mp4'} type="video/mp4" />
          </video>

          <div
            className="p-5 text-start text-center z-3"
            style={{ whiteSpace: 'pre-line', display: 'block' }}
          >
            <h1 className="banner-title text-white w-100 m-auto" id="dynamic-text">
              {''}
            </h1>
            {/* <h1 className="banner-title text-white w-100 m-auto" id="animate-1">
              Becoming a SOVRUN Investor.
            </h1>
            <h1 className="banner-title text-white w-100 m-auto" id="animate-2">
              Gain knowledge.
            </h1>
            <h1 className="banner-title text-white w-100 m-auto" id="animate-3">
              Analyze.
            </h1>
            <h1 className="banner-title text-white w-100 m-auto" id="animate-4">
              Grow.
            </h1> */}
            {/* <h1 className="banner-title text-white w-50 m-auto">Analyze like a pro.</h1>
            <h1 className="banner-title text-white w-100 m-auto">
              Execute systematically and mechanically.
            </h1>
            <h1 className="banner-title text-white w-100 m-auto">
              Become SOVRUN of your investments.
            </h1> */}
            <p className="banner-subtitle text-white my-4">
              SOVRUN is designed to help investors become independent.
            </p>
          </div>
          <div className="d-inline-flex gap-2 my-5 z-3">
            <a className=" btn btn-outline-secondary btn-lg me-4" href="/">
              Explore
            </a>
            <span
              className="btn  rounded-pill"
              type="button"
              onClick={(e) => {
                setShowSubModal(true)
              }}
            >
              Subscribe
            </span>
          </div>
        </div>
      </div>
      <div className="bg-dark py-5">
        <div className="container">
          <div className="row py-5 mt-5">
            <div className="col-12 col-md-6 px-5 mb-5">
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="img">
                  <img src="./assets/image/briefcase.svg" alt="" />
                </div>
                <div className="title text-white">
                  What does <span className="fw-bold d-block">Sovrun do? </span>
                </div>
              </div>
              <div className="content text-white lh-lg text-center text-md-start">
                SOVRUN, a product of Mosaic Automation Inc., is an automated trading platform
                designed to help self-directed investors and portfolio managers better manage their
                investments through strategic, tech-forward strategies. The platform enables our
                customers to analyze, build, and implement unique trading and risk strategies to
                best achieve their goals.
              </div>
            </div>

            <div className="col-12 col-md-6 px-5 mb-5">
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="img">
                  <img src="./assets/image/STATISTICS.svg" alt="" />
                </div>
                <div className="title text-white">
                  What is our <span className="fw-bold d-block">company mission? </span>
                </div>
              </div>
              <div className="content text-white lh-lg text-center text-md-start">
                Our mission is centered on educating customers to invest from an institutional
                perspective by providing tools and techniques from multiple sources. By focusing on
                education, we can help them navigate the proprietary strategies and create a
                customized portfolio aimed at providing high risk adjusted returns to clients
                through systematic and mechanical trading methodologies.
              </div>
            </div>

            <div className="col-12 my-5">
              <div className="row flex-row align-items-center">
                <div className="col-12 col-md-5 d-flex flex-column flex-md-row">
                  <div className="img text-center text-md-start">
                    <img src="./assets/image/arrow.svg" alt="" />
                  </div>
                  <div className="title text-white text-center text-md-start">
                    Are you ready to
                    <span className="fw-bold d-block"> manage risks? </span>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="content text-white lh-lg text-center text-md-start">
                    Our proprietary risk management strategies encompass the core of our philosophy.
                    These strategies are not just sets of rules; they’re adaptive, intelligent
                    systems that learn from market patterns and evolve with them. They’re designed
                    to identify potential risks before they become problematic, ensuring that our
                    clients are always a step ahead.
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <a href="/about-us" className="btn link-button">
                About Us
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Algorithmic strategies */}
      <section className="algorithmic">
        <div className="container">
          <div className="row">
            <Strategies />
          </div>
        </div>
      </section>

      {/* Person */}
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
              <div className="text-center person-section p-5">
                <div className="avatar">
                  <img src="./assets/image/person.png" alt="" />
                </div>
                <div className="person-name text-white my-4">Robert J. Ogilvie</div>
                <div className="person-desc text-white">
                  explains the philosophy <span className="fw-bold color-blue">of Sovrun.</span>
                </div>
                <div>
                  <img
                    className="play-btn"
                    onClick={(e) => setShowModal(true)}
                    src="./assets/image/play-button.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Slider */}
      <div className="bg-dark-20 py-5 extra-margin">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center slider p-5">
              <div className="text-white content mb-4">
                <span className="d-block">&quot;</span>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis et quasi architecto.
              </div>
              <div className="my-5">
                <img src="./assets/image/avatar.png" alt="" className="my-5" />
                <p className="text-white">
                  Jack Taylor <br />
                  Product Manager,<span className="trademark"> Apple INC</span>
                </p>
              </div>
              <div>
                <nav aria-label="...">
                  <ul className="pagination pagination-sm justify-content-center">
                    <li className="page-item active" aria-current="page">
                      <span className="page-link">1</span>
                    </li>
                    <li className="page-item mx-3">
                      <a className="page-link" href="/">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        3
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Videos */}
      <div className="videos extra-padding">
        <div className="container">{videos && <VideosSlide videos={videos} />}</div>
      </div>

      {/* Info */}
      {/* <div className="info  bg-dark extra-padding">
        <div className="container info-container rounded-4 py-5">
          <div className="row">
            <div className="col-12 col-md-4 offset-md-2">
              <div className="info-title text-white mb-4 text-center text-md-start">
                All packages at one starting price.
              </div>
              <div className="info-text d-flex flex-md-row flex-column text-center text-md-start align-items-center mb-4 mb-md-0">
                <div className="green w-25">Get a Free Trail</div>
                <div className="text-white grey w-50">
                  Start your <span className="fw-bold">14-day</span> trial period to use Sovrun
                  algorithms. ⚙️
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="info-list">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {' '}
                    Lorem ipsum dolor sit amet conversions with.{' '}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    Lorem ipsum dolor sit amet conversions with.{' '}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    Lorem ipsum dolor sit amet conversions with.{' '}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    Lorem ipsum dolor sit amet conversions with.{' '}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    Lorem ipsum dolor sit amet conversions with.{' '}
                  </li>
                </ul>
              </div>
              <div className="info-button mt-4">
                <button className="btn">After 14 days, the starter package is $100</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <footer className="">
        <div className="container container container-lg footer-container rounded-4 p-5">
          <p className="text-white contact-title">Contact</p>
          <div className="mb-5 text-white">
            Get in touch with us to find answers to your queries.
          </div>
          <div className="row my-5">
            <div className="col-12 col-md-6 mb-4">
              <form>
                <div className="row">
                  <div className="col-6 mb-4">
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="form-control w-100"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="col-6 mb-4">
                    <input
                      type="text"
                      placeholder="Your Email"
                      className="form-control"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <input
                      type="text"
                      placeholder="Subject"
                      className="form-control"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <textarea
                      name=""
                      className="form-control"
                      placeholder="Message"
                      id=""
                      cols="15"
                      rows="5"
                    ></textarea>
                  </div>
                  <div className="col-12 col-md-12 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn rounded-4 rounded-pill px-5"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* <div className="col-12 col-md-6 mb-3 address d-flex flex-column flex-md-row justify-content-center align-items-center rounded-4">
              <div>
                <img src="./assets/image/location.svg" alt="sovrun location" />
              </div>
              <p className="text-white text-center text-md-start">
                1962 Main Street, Sarasota, FL USA <br />
                <br />
                +65 22 333 22 00
              </p>
            </div> */}
          </div>
        </div>
      </footer>
      <Footer></Footer>
      <Modal show={show_modal} onHide={handleCloseModal} id="videoplayer" centered size="xl">
        <Modal.Header>
          <CloseButton onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url="./assets/videos/SOVRUNWebIntro1.MP4"
            width={1118}
            height={950}
            controls={true}
          />
        </Modal.Body>
      </Modal>
      <Modal show={show_sub_modal} onHide={handleCloseSubModal} id="show-sub-modal" centered>
        <Modal.Header>
          <CloseButton onClick={handleCloseSubModal} />
        </Modal.Header>
        <Modal.Body>
          <h4>Have you requested a demo?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowSubModal(false)
              setShowDemoModal(true)
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              setShowSubModal(false)
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show_demo_modal} onHide={handleCloseDemoModal} id="show-sub-modal" centered>
        <Modal.Header>
          <CloseButton onClick={handleCloseDemoModal} />
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you don’t want a demo?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">No</Button>
          <Button
            variant="primary"
            // onClick={(e) => {
            //   navigate('/account')
            // }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Home
