import React from 'react'
import { useEffect, useState } from 'react'
import Chart from './Chart'
import { ProductApiService } from '../utils/productApiService'
import { currency_format_usdolar, decimal_format } from '../utils/common'
function Strategies(props) {
  const [strategies, setStrategies] = useState()
  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_algoritmic_strategies().then((response) => {
      setStrategies(response)
    })
  }, [])

  const strategy_switcher_Onchange = (e) => {
    // console.log(e)
    const strategyPerformance = document.getElementById(`strategy-performance-${e.target.id}`)
    const strategyProfile = document.getElementById(`strategy-profile-${e.target.id}`)

    if (e.target.checked) {
      strategyPerformance.classList.remove('d-none')
      strategyPerformance.classList.add('d-block')

      strategyProfile.classList.remove('d-block')
      strategyProfile.classList.add('d-none')
    } else {
      strategyPerformance.classList.remove('d-block')
      strategyPerformance.classList.add('d-none')

      strategyProfile.classList.remove('d-none')
      strategyProfile.classList.add('d-block')
    }
  }
  return (
    <>
      <div className="col-12 col-md-3">
        <h5>Strategies</h5>
        <div className="list-group" id="list-tab" role="tablist">
          {strategies &&
            strategies.map((strategy, index) => {
              return (
                <a
                  key={index}
                  className={`list-group-item list-group-item-action ${index === 0 ? 'active' : ''}`}
                  id={`list-${index}-list`}
                  data-bs-toggle="list"
                  href={`#list-${index}`}
                  role="tab"
                  aria-controls={`list-${index}`}
                >
                  {String(index + 1).padStart(2, '0')} {strategy.title}
                </a>
              )
            })}
        </div>
      </div>
      <div className="col-12 col-md-9">
        <div className="tab-content" id="nav-tabContent">
          {strategies &&
            strategies.map((strategy, index) => {
              return (
                <div
                  key={index}
                  className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                  id={`list-${index}`}
                  role="tabpanel"
                  aria-labelledby={`list-${index}-list`}
                >
                  <div className="chart" id={`sovrunChart-${strategy.id}`}>
                    <div className="d-flex justify-content-between flex-column flex-md-row align-items-center mb-5">
                      <div className="title">{strategy.name}</div>
                      <div className="switch-container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id={strategy.id}
                            onChange={(e) => strategy_switcher_Onchange(e)}
                          />
                          <span className="switch-slider round"></span>
                          <div className="cont">
                            <div className="first">Strategy Profile</div>
                            <div className="second">Strategy Performance</div>
                          </div>
                        </label>
                      </div>
                    </div>

                    {/* Strategy Performance */}
                    <div
                      className="strategy-performance d-none"
                      id={`strategy-performance-${strategy.id}`}
                    >
                      <Chart data={strategy.chart}></Chart>
                      <canvas key={strategy.id} id={`sovrunChart-${strategy.id}`}></canvas>
                    </div>

                    {/* Strategy Profile */}
                    <div
                      className="strategy-profile d-block"
                      id={`strategy-profile-${strategy.id}`}
                    >
                      <div className="bg-white rounded-4 p-md-5">
                        <p className="mb-5" style={{ whiteSpace: 'pre-line' }}>
                          {strategy.description}
                        </p>
                        {/* <p className="mb-5">{strategy.desc2}</p> */}
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-center position-relative">
                              <div>
                                <img src="./assets/image/Ellipse.svg" alt="" />
                              </div>
                              <div className="amount position-absolute top-50 text-center">
                                <p className="title mb-0">Baseline Capital</p>
                                <p className="price">
                                  ${' '}
                                  {strategy.strategy_profile.length > 0
                                    ? decimal_format(strategy.strategy_profile[0]?.total_equity)
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-8 mt-4 mt-md-0">
                            <table className="table table-sm table-borderless">
                              <tbody>
                                <tr>
                                  <td>Total Trades</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? decimal_format(strategy.strategy_profile[0].total_trades)
                                        : ''}
                                    </span>
                                  </td>
                                  <td>Average Loser</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? currency_format_usdolar(
                                            strategy.strategy_profile[0].average_loser,
                                          )
                                        : ''}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td> Winning Trades</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? decimal_format(
                                            strategy.strategy_profile[0].winning_trades,
                                          )
                                        : ''}
                                    </span>
                                  </td>
                                  <td>Average Net</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? currency_format_usdolar(
                                            strategy.strategy_profile[0].average_net,
                                          )
                                        : ''}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td> Losing Trades</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? decimal_format(strategy.strategy_profile[0].losing_trades)
                                        : ''}
                                    </span>
                                  </td>
                                  <td>Max Drawdown</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? decimal_format(
                                            strategy.strategy_profile[0].max_drawdown * 100,
                                            2,
                                          )
                                        : ''}
                                      %
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td> Average Winner</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? currency_format_usdolar(
                                            strategy.strategy_profile[0].average_winner,
                                          )
                                        : ''}
                                    </span>
                                  </td>
                                  <td>Average Year</td>
                                  <td>
                                    :{' '}
                                    <span>
                                      {strategy.strategy_profile.length > 0
                                        ? decimal_format(
                                            strategy.strategy_profile[0].average_year * 100,
                                            2,
                                          )
                                        : ''}
                                      %
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 col-lg-10 offset-lg-1">
                            <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
                              <div className="element d-flex align-items-center mb-4 mb-md-0">
                                <span className="el-circle"></span>
                                <p className="d-inline-flex mb-0 ms-3">
                                  Sharpe Ratio:{' '}
                                  <span className="value px-3">
                                    {' '}
                                    {strategy.strategy_profile.length > 0
                                      ? strategy.strategy_profile[0].sharpe_ratio !== null
                                        ? strategy.strategy_profile[0].sharpe_ratio.toFixed(2)
                                        : null
                                      : ''}
                                  </span>
                                </p>
                              </div>
                              <div className="element d-flex align-items-center mb-4 mb-md-0">
                                <span className="el-circle"></span>
                                <p className="d-inline-flex mb-0 ms-3">
                                  Sortino Ratio:{' '}
                                  <span className="value px-3">
                                    {' '}
                                    {strategy.strategy_profile.length > 0
                                      ? strategy.strategy_profile[0].sortino_ratio !== null
                                        ? strategy.strategy_profile[0].sortino_ratio.toFixed(2)
                                        : null
                                      : ''}
                                  </span>
                                </p>
                              </div>
                              <div className="element d-flex align-items-center mb-4 mb-md-0">
                                <span className="el-circle"></span>
                                <p className="d-inline-flex mb-0 ms-3">
                                  Calmar Ratio:{' '}
                                  <span className="value px-3">
                                    {' '}
                                    {strategy.strategy_profile.length > 0
                                      ? strategy.strategy_profile[0].calmar_ratio !== null
                                        ? strategy.strategy_profile[0].calmar_ratio.toFixed(2)
                                        : null
                                      : ''}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default Strategies
