/* eslint-disable no-unused-vars */
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useState, useEffect } from 'react'
import { ranger } from '../js/sovrun'
import { ProductApiService } from '../utils/productApiService'
import { PopupModal } from 'react-calendly'

function Account() {
  const [range, setRange] = useState(0)

  const [first_name, setFirstName] = useState()
  const [last_name, setLastName] = useState()
  const [email, setEmail] = useState()
  const [country, setCountry] = useState()
  const [password, setPassword] = useState('12345678')
  const [password2, setPassword2] = useState('12345678')
  const [trading_experience, setTradingExperience] = useState(1)
  const [future_experience, setFutureExperience] = useState(1)
  const [min_price, setMinPrice] = useState('500.00')
  const [max_price, setMaxPrice] = useState('4000.00')
  const [currency, setCurrency] = useState('USD')
  const [stock_level_of_experience, setStockLevelOfExperience] = useState('Beginner Level')
  const [features_level_of_experience, setFeaturesLevelOfExperience] = useState('Beginner Level')
  const [long_term_investments, setLongTermInvestments] = useState('')
  const [short_term_trades, setShortTermTrades] = useState('')
  const [regular_income_investments, setRegularIncomeInvestments] = useState('')
  const [hedging_strategies, setHedgingStrategies] = useState('')
  const [diversification_strategies, setDiversificationStrategies] = useState('')
  const [show_calendly, setShowCalendly] = useState(false)

  const getMinMaxPrices = () => {
    let lower = document.getElementById('lower-value').innerHTML
    let upper = document.getElementById('upper-value').innerHTML

    let lower_decimal = parseFloat(lower.replace('$', ''))
    let upper_decimal = parseFloat(upper.replace('$', ''))
    setMinPrice(lower_decimal)
    setMaxPrice(upper_decimal)
  }
  const ApiService = new ProductApiService()
  const handleOpenAccount = (e) => {
    e.preventDefault()
    getMinMaxPrices()
    if (stock_level_of_experience === '') {
      alert('Please indicate your level of interest and experience in equities.')
      return
    }
    if (features_level_of_experience === '') {
      alert('What is your level of experience and interest in futures contracts?')
      return
    }
    let data = {
      username: first_name,
      email: email,
      first_name: first_name,
      last_name: last_name,
      country: country,
      trading_experience: trading_experience,
      price_range_min: min_price,
      price_range_max: max_price,
      currency: currency,
      stock_level_of_experience: stock_level_of_experience,
      features_level_of_experience: features_level_of_experience,
      long_term_investments: long_term_investments,
      short_term_trades: short_term_trades,
      regular_income_investments: regular_income_investments,
      hedging_strategies: hedging_strategies,
      diversification_strategies: diversification_strategies,
    }
    ApiService.post_appointments(data).then((response) => {
      console.log(response)
      setShowCalendly(response)
    })
  }
  useEffect(() => {
    ranger()
  }, [])
  useEffect(() => {
    let first_name_valid = document.getElementById('first-name-valid')
    let last_name_valid = document.getElementById('last-name-valid')
    let email_valid = document.getElementById('email-valid')
    if (first_name === null || first_name === undefined || first_name === '') {
      first_name_valid.style.display = 'block'
    } else {
      first_name_valid.style.display = 'none'
    }

    if (last_name === null || last_name === undefined || last_name === '') {
      last_name_valid.style.display = 'block'
    } else {
      last_name_valid.style.display = 'none'
    }

    if (email === null || email === undefined || email === '') {
      email_valid.style.display = 'block'
    } else {
      email_valid.style.display = 'none'
    }
  }, [first_name, last_name, email])
  return (
    <>
      <Header />
      <section className="py-5 bg-dark account">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 mb-5">
              <div>
                <div className="text-white text-center text-md-start d-flex flex-md-row flex-column align-items-center bg-blue rounded-5">
                  <img src="./assets/image/gold.png" className="img-fluid" alt="gold" />
                  <div>
                    <p className="title">Book An Appointment</p>
                    {/* <p>
                      All strategies at one starting price…
                      <span className="fw-bold">FREE for the first 14 day!</span>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 offset-lg-1">
              <form className="account-form" action="">
                <h4 className="text-white fw-bold mb-4">Personal Information</h4>
                <div className="row">
                  <div className="mb-3 col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="first-name"
                      placeholder="First Name"
                      onInput={(e) => setFirstName(e.target.value)}
                      required="true"
                    />
                    <div id="first-name-valid" className="invalid-feedback">
                      Please fill out first name field.
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="last-name"
                      placeholder="Last Name"
                      onInput={(e) => setLastName(e.target.value)}
                      required
                    />
                    <div id="last-name-valid" className="invalid-feedback">
                      Please fill out last name field.
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      onInput={(e) => setEmail(e.target.value)}
                      required
                    />
                    <div id="email-valid" className="invalid-feedback">
                      Please fill out email field.
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    {/* <input type="text" className="form-control" id="formGroupExampleInput2" placeholder="Country"> */}
                    <select
                      className="form-select bg-dark-20 tc-63 p-4"
                      aria-label="Default select example"
                      defaultValue="USA"
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    >
                      <option value="">Country</option>
                      <option value="Turkey">Turkey</option>
                      <option value="England">England</option>
                      <option value="USA">USA</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  {/* <div className="mb-3 col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Password"
                      onInput={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput2"
                      placeholder="Again Password"
                      onInput={(e) => setPassword2(e.target.value)}
                    />
                  </div> */}
                </div>

                <h4 className="text-white mt-5">Investment Information</h4>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="customRange1" className="form-label tc-63">
                      Trading/Investment Experience
                    </label>
                    <div className="my-5">
                      <div className="sliderticks mb-4">
                        <span>0-5 years</span>
                        <span>5-10 years</span>
                        <span>10-15 years</span>
                        <span>+15 years</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="4"
                        value={trading_experience}
                        onChange={(event) => setTradingExperience(event.target.value)}
                        className="range-input"
                        id="range4"
                        step="1"
                      />
                    </div>
                  </div>
                </div>
                <h4 className="text-white mt-5">Stocks/ETFs</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="text-color-81">
                      Please indicate your level of interest and experience in equities.{' '}
                    </p>

                    <div className="row">
                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={true}
                            name="flexRadioDefaulta"
                            id="flexRadioDefault4"
                            onClick={(e) => setStockLevelOfExperience('Beginner Level')}
                          />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault4"
                          >
                            <p className="text-white fw-bold">Beginner Level</p>
                            <p className="tc-63">
                              Basic knowledge <br /> and experience in stocks.
                            </p>
                            <br />
                          </label>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefaulta"
                            id="flexRadioDefault5"
                            onClick={(e) => setStockLevelOfExperience('Intermediate Level')}
                          />
                          <br />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault5"
                          >
                            <p className="text-white fw-bold">Intermediate Level</p>
                            <p className="tc-63">
                              Active engagement in stock trading and monitoring.
                            </p>
                            <br />
                          </label>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefaulta"
                            id="flexRadioDefault6"
                            onClick={(e) => setStockLevelOfExperience('Advanced Level')}
                          />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault6"
                          >
                            <p className="text-white fw-bold">Advanced Level</p>
                            <p className="tc-63">
                              Complex futures trading strategies and market analysis expertise.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="customRange1" className="form-label tc-63">
                      Future/Investment Experience
                    </label>
                    <div className="my-5">
                      <div className="sliderticks mb-4">
                        <span>0-5 years</span>
                        <span>5-10 years</span>
                        <span>10-15 years</span>
                        <span>+15 years</span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="4"
                        value={future_experience}
                        onChange={(event) => setFutureExperience(event.target.value)}
                        className="range-input"
                        id="range4"
                        step="1"
                      />
                    </div>
                  </div>
                </div>
                <h4 className="text-white mt-5">Commodity Futures</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="text-color-81">
                      What is your level of experience and interest in futures contracts?{' '}
                    </p>
                    <div className="row">
                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={true}
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            onClick={(e) => setFeaturesLevelOfExperience('Beginner Level')}
                          />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault1"
                          >
                            <p className="text-white fw-bold">Beginner Level</p>
                            <p className="tc-63">
                              Introduction to futures and foundational knowledge.
                            </p>
                          </label>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onClick={(e) => setFeaturesLevelOfExperience('Intermediate Level')}
                          />
                          <br />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault2"
                          >
                            <p className="text-white fw-bold">Intermediate Level</p>
                            <p className="tc-63">Active trading in futures and risk management.</p>
                            <br />
                          </label>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="p-4 bg-dark-20 rounded-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            onClick={(e) => setFeaturesLevelOfExperience('Advanced Level')}
                          />
                          <label
                            className="form-check-label text-white d-block mt-5"
                            htmlFor="flexRadioDefault3"
                          >
                            <p className="text-white fw-bold">Advanced Level</p>
                            <p className="tc-63">
                              Complex futures trading strategies and market analysis expertise.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="text-white mt-5">Investable Assets</h4>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="customRange1" className="form-label text-color-81">
                      Please indicate the amount of assets you are willing to invest. This
                      information helps us tailor investment strategies that align with your
                      financial capacity and goals.
                    </label>
                    <div className="my-5 bg-dark-20 p-3 rounded-4">
                      <div className="mb-4 tc-63">Price</div>
                      {/* <input type="range" min="0" max="100" value="0" className="range-input" id="input-select" step="50" /> */}
                      <div className="example">
                        <div
                          id="nonlinear"
                          className="noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between tc-63 mt-3">
                        <div>$10,000</div>
                        <div>+$1,000,000+</div>
                      </div>

                      <div className="d-flex align-items-center justify-content-start mt-5 text-white">
                        <span className="tc-63"></span>{' '}
                        <span
                          className="example-val text-white bg-dark-60 mx-4 rounded-4 p-3"
                          id="lower-value"
                        >
                          ${min_price}
                        </span>
                        {/*to
                        <span
                          className="example-val text-white bg-dark-60 mx-4 rounded-4 p-3"
                          id="upper-value"
                        >
                          ${max_price}
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <h4 className="text-white mt-5">Currency</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="text-color-81">Select the currency you are using to trade.</p>
                    <div className="row">
                      <div className="col-12">
                        <div className="bg-dark-20 p-4 rounded-4 d-flex justify-content-between">
                          <p className="tc-63 mb-0"> {`Currency: ${currency}`}</p>
                          <div>
                            <input
                              type="radio"
                              className="btn-check"
                              checked
                              name="options"
                              id="btn-check-usd"
                              autoComplete="off"
                              onClick={(e) => setCurrency('USD')}
                            />
                            <label className="btn bg-dark-60" htmlFor="btn-check-usd">
                              USD
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="options"
                              id="btn-check-eur"
                              autoComplete="off"
                              onClick={(e) => setCurrency('EUR')}
                            />
                            <label className="btn bg-dark-60" htmlFor="btn-check-eur">
                              EUR
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="options"
                              id="btn-check-gbp"
                              autoComplete="off"
                              onClick={(e) => setCurrency('GBP')}
                            />
                            <label className="btn bg-dark-60" htmlFor="btn-check-gbp">
                              GBP
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="text-white mt-5">Strategy of Interest</div>
                <div className="row">
                  <div className="col-12">
                    <p className="text-color-81">
                      Which investment strategies are you interested in?{' '}
                    </p>
                    <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                        onClick={(e) => setLongTermInvestments(e.target.checked)}
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault1">
                        <p className="text-white mb-0">Long-Term Investments</p>
                        <span className="tc-63">
                          Introduction to futures and foundational knowledge.
                        </span>
                      </label>
                    </div>
                    <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault2"
                        onClick={(e) => setShortTermTrades(e.target.checked)}
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault2">
                        <p className="text-white mb-0">Short-Term Trades</p>
                        <span className="tc-63">
                          Short-term transactions focused on quick gains.
                        </span>
                      </label>
                    </div>
                    <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault3"
                        onClick={(e) => setRegularIncomeInvestments(e.target.checked)}
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault3">
                        <p className="text-white mb-0">Regular Income Investments</p>
                        <span className="tc-63">
                          Investments targeting income generation through dividends.
                        </span>
                      </label>
                    </div>
                    <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault4"
                        onClick={(e) => setHedgingStrategies(e.target.checked)}
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault4">
                        <p className="text-white mb-0">Hedging Strategies</p>
                        <span className="tc-63">
                          Tactics to protect the portfolio against market fluctuations.
                        </span>
                      </label>
                    </div>
                    <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault5"
                        onClick={(e) => setDiversificationStrategies(e.target.checked)}
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault5">
                        <p className="text-white mb-0">Diversification Strategies</p>
                        <span className="tc-63">
                          Techniques for portfolio diversification to reduce risk.
                        </span>
                      </label>
                    </div>
                    {/* <div className="bg-dark-20 p-4 rounded-4 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault6"
                      />
                      <label className="form-check-label ms-4" htmlFor="flexCheckDefault6">
                        <p className="text-white mb-0">Regular Income Investments</p>
                        <span className="tc-63">
                          Investments targeting income generation through dividends.
                        </span>
                      </label>
                    </div> */}
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-between flex-column flex-md-row align-items-center">
                  <div>
                    <div className="g-recaptcha" data-sitekey="PASTE-YOUR-SITE_KEY-HERE">
                      {/* <div style={{ width: '304px', height: '78px' }}>
                        <div>
                          <iframe
                            title="reCAPTCHA"
                            width="304"
                            height="78"
                            role="presentation"
                            name="a-9tn24uaucofd"
                            frameBorder="0"
                            scrolling="no"
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                            src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=PASTE-YOUR-SITE_KEY-HERE&amp;co=aHR0cDovLzEyNy4wLjAuMTo1NTAw&amp;hl=en&amp;v=joHA60MeME-PNviL59xVH9zs&amp;size=normal&amp;cb=n5fg7nmocpnp"
                          ></iframe>
                        </div>
                        <textarea
                          id="g-recaptcha-response"
                          name="g-recaptcha-response"
                          className="g-recaptcha-response"
                          style={{
                            width: '250px',
                            height: '40px',
                            border: '1px solid rgb(193, 193, 193)',
                            margin: '10px 25px',
                            padding: '0px',
                            resize: 'none',
                            display: 'none',
                          }}
                        ></textarea>
                      </div> */}
                      <iframe style={{ display: 'none' }}></iframe>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button className="btn link-button" onClick={(e) => handleOpenAccount(e)}>
                      {' '}
                      Book an Appointment
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="bg-dark-20  mt-5 rounded-5 p-5">
              <p className="fw-bold text-white subtitle">Legal Disclaimer on Investment Results</p>

              <p className="text-white content lh-lg">
                <img src="./assets/image/warning-icon.svg" alt="" />
                HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE
                DESCRIBED BELOW. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY
                TO ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE SHOWN. IN FACT, THERE ARE FREQUENTLY
                SHARP DIFFERENCES BETWEENHYPOTHETICAL PERFORMANCE RESULTS AND THE ACTUAL RESULTS
                SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING PROGRAM. ONE OF THE LIMITATIONS OF
                HYPOTHETICAL PERFORMANCE RESULTS IS THAT THEY ARE GENERALLY PREPARED WITH THE
                BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL TRADING DOES NOT INVOLVE FINANCIAL
                RISK, AND NO HYPOTHETICAL TRADING RECORD CAN COMPLETELY ACCOUNT htmlFor THE IMPACT
                OF FINANCIAL RISK IN ACTUAL TRADING. htmlFor EXAMPLE, THE ABILITY TO WITHSTAND
                LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN SPITE OF TRADING LOSSES ARE
                MATERIAL POINTS WHICH CAN ALSO ADVERSELY AFFECT ACTUAL TRADING RESULTS. THERE ARE
                NUMEROUS OTHER FACTORS RELATED TO THE MARKETS IN GENERAL OR TO THE IMPLEMENTATION OF
                ANY SPECIFIC TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED htmlFor IN THE
                PREPARATION OF HYPOTHETICAL PERFORMANCE RESULTS AND ALL OF WHICH CAN ADVERSELY
                AFFECT ACTUAL TRADING RESULTS.
              </p>
              <p className="text-5C content lh-lg">
                NOTE: RETURNS AND STATISTICS ARE PROFORMA (HYPOTHETICAL) AND ARE EXCLUSIVE
                SUBSCRIPTION FEES. RETURNS AND STATISTICS FROM THE PROGRAMS ARE INCLUSIVE OF
                COMMISSIONS. PAST PERFORMANCE IS NOT NECESSARILY INDICATIVE OF FUTURE RESULTS.
                FUTURES TRADING INVOLVES SUBSTANTIAL RISK OF LOSS AND IS NOT SUITABLE htmlFor ALL
                INVESTORS. ONLY RISK CAPITAL SHOULD BE USED.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      <PopupModal
        url="https://calendly.com/rogilvie-mosaicautomation"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById('root')}
        onModalClose={() => setShowCalendly(false)}
        open={show_calendly}
        textColor="#ffffff"
        color="#00a2ff"
      />
    </>
  )
}

export default Account
