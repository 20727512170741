import React from 'react'

function Footer() {
  return (
    <footer className="">
      <div className="container container container-lg footer-container rounded-4 p-5">
        <div className="row">
          <div className="d-flex flex-md-row flex-column justify-content-between py-4 copyright">
            <p className="text-white text-center text-md-start">
              © Copyright 2023 All Rights Reserved
            </p>
            <div className="d-flex justify-content-between">
              <a className="text-white text-decoration-none me-2" href="/about-us">
                About
              </a>
              <a className="text-white text-decoration-none me-2" href="/strategies">
                Strategies
              </a>
              <a className="text-white text-decoration-none me-2" href="/videos">
                Videos
              </a>
              <a className="text-white text-decoration-none" href="/privacy-statement">
                Privacy
              </a>
              <a className="text-white text-decoration-none" href="/contact">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
