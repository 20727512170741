import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

function Chart(props) {
  console.log(props.data)
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
  let options = {
    legend: { display: false },
  }
  function generateColor(index) {
    let colors = ['#7dcea0', '#3498db', '#e67e22', '#f7dc6f']
    return colors[index]
    //const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    //return '#' + randomColor
  }
  let values = props.data.map((value, index) => {
    return {
      data: Object.values(value[Object.keys(value)[0]]),
      borderColor: generateColor(index),
      borderRadius: '10',
      fill: false,
      label: Object.keys(value)[0],
      tension: 0.5,
    }
  })
  let data = {
    labels: Object.keys(props.data[0][Object.keys(props.data[0])[0]]),
    datasets: values,
  }
  return <Line options={options} data={data} />
}

export default Chart
