import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
function Privacy() {
  return (
    <>
      <Header></Header>
      <section className="subbanner bg-blue d-flex align-items-center justify-content-center">
        <h1 className="text-white text-center">Privacy Statement</h1>
      </section>
      <footer className="">
        <div className="container container container-lg footer-container rounded-4 p-5">
          <div className="row">
            <div className="mb-5 text-white fs-2">
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '0cm',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  Pursuant to the Commodity Futures Trading Commissions new rules, financial
                  institutions like MOSAIC AUTOMATION INC (MOSAIC aka SOVRUN) are required to
                  provide privacy notices to their clients. We at MOSAIC consider privacy to be
                  fundamental to our relationship with our clients. We are committed to maintaining
                  the confidentiality, integrity and security of our current and former clients’
                  non-public information. Accordingly, we have developed internal polices to protect
                  confidentiality while allowing clients’ needs to be met.
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '0cm',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  We will not disclose any non-public personal information about clients, except to
                  our affiliates and service providers as allowed by applicable law or regulation.
                  In the normal course of serving our clients, information we collect may be shared
                  with companies that perform various services such as our accountants, auditors and
                  attorneys. Specifically, we may disclose these service providers nonpublic
                  personal information including:
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '10.5pt',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  •&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Information MOSAIC receives from clients
                  on managed account agreements and related forms (such as name, address, Social
                  Security/Tax identification number, birth date, assets, income and investment
                  experience); and
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '10.5pt',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  •&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Information about clients’ transactions
                  with MOSAIC (such as account activity and account balances).
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '0cm',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  Any party that receives this information will use it only for the services
                  required and as allowed by applicable law or regulation, and is not permitted to
                  share or use this information for any other purpose. To protect the personal
                  information of individuals, we permit access only by authorized employees who need
                  access to that information to provide services to our clients and us. In order to
                  guard clients’ non-public personal information, we maintain physical, electronic
                  and procedural safeguards that comply with the U.S. federal standards. If the
                  relationship between a client and MOSAIC ends, MOSAIC will continue to treat
                  clients’ personal information as described in this notice. An individual client’s
                  right to privacy extends to all forms of contact with MOSAIC, including telephone,
                  written correspondence and electronic media, such as email messages via the
                  Internet.
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '0cm',

                  textAlign: 'justify',
                }}
              >
                <span style={{}}>
                  MOSAIC reserves the right to change this privacy notice, and to apply changes to
                  information previously collected, as permitted by law. MOSAIC will inform clients
                  of any such changes as required by law.
                </span>
              </p>
              <p
                style={{
                  marginTop: '0cm',
                  marginRight: '0cm',
                  marginBottom: '6.0pt',
                  marginLeft: '0cm',
                  textIndent: '0cm',
                }}
              >
                <span style={{}}>
                  Any questions regarding this Privacy Statement should be directed to Robert J.
                  Ogilvie, at (941) 260-3333 or&nbsp;
                </span>
                <a href="mailto:ROgilvie@mosaicautomation.com">
                  <span style={{}}>ROgilvie@mosaicautomation.com</span>
                </a>
                <span style={{}}>.</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <Footer></Footer>
    </>
  )
}

export default Privacy
