import React from 'react'
import About from './pages/About'
import Account from './pages/Account'
import Algorithmic from './pages/Algorithmic'
import Contact from './pages/Contact'
import Videos from './pages/Videos'
import Home from './pages/Home'
import Privacy from './pages/Privacy'
import { store } from './app/api/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/account" element={<Account />} />
          <Route path="/strategies" element={<Algorithmic />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/privacy-statement" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
