import React from 'react'
import Header from '../components/Header'
import AllVideos from '../components/AllVideos'
import Footer from '../components/Footer'
import { ProductApiService } from '../utils/productApiService'
import { useEffect, useState } from 'react'
import CloseButton from 'react-bootstrap/CloseButton'

function Videos() {
  const [videos, setVideos] = useState()
  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_videos().then((response) => {
      setVideos(response)
    })
  }, [])

  return (
    <>
      <Header></Header>
      <section className="subbanner bg-blue d-flex align-items-center justify-content-center">
        <h1 className="text-white text-center">Videos</h1>
      </section>
      <div className="videos">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 my-5">
              <div className="text-center text-white fs-4">
                Our video broadcasts on ever-evolving financial technologies and strategies.{' '}
              </div>
            </div>
            {videos && <AllVideos videos={videos} />}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="info-button mt-4 text-center">
                {/* <button className="btn">More Videos</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default Videos
