import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function About() {
  return (
    <>
      <Header></Header>
      <section className="subbanner bg-blue d-flex align-items-center justify-content-center">
        <h1 className="text-white text-center">About Us</h1>
      </section>
      <div className="bg-dark py-5">
        <div className="container">
          <div className="row gy-5">
            <div className="col-12 px-5 mb-5 box-padding box-padding-border-bottom">
              <div className="row">
                <div className="col-12 col-md-5">
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="img">
                      <img src="./assets/image/briefcase.svg" alt="" />
                    </div>
                    <div className="title text-white">
                      What does <span className="fw-bold d-block">Sovrun do? </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="content text-white lh-lg text-center text-md-start">
                    SOVRUN, a product of Mosaic Automation Inc., is an automated trading platform
                    designed to help self-directed investors and portfolio managers better manage
                    their investments through strategic, tech-forward strategies. The platform
                    enables our customers to analyze, build, and implement unique trading and risk
                    strategies to best achieve their goals.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 px-5 mb-5 box-padding box-padding-border-bottom">
              <div className="row">
                <div className="col-12 col-md-5">
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="img">
                      <img src="./assets/image/STATISTICS.svg" alt="" />
                    </div>
                    <div className="title text-white">
                      What is our <span className="fw-bold d-block">company mission? </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="content text-white lh-lg text-center text-md-start">
                    Our mission is centered on educating customers to invest from an institutional
                    perspective by providing tools and techniques from multiple sources. By focusing
                    on education, we can help them navigate the proprietary strategies and create a
                    customized portfolio aimed at providing high risk adjusted returns to clients
                    through systematic and mechanical trading methodologies.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-5 box-padding box-padding-border-bottom">
              <div className="row flex-row align-items-center">
                <div className="col-12 col-md-5 d-flex flex-column flex-md-row text-center text-md-start">
                  <div className="img">
                    <img src="./assets/image/arrow.svg" alt="" />
                  </div>
                  <div className="title text-white">
                    Are you ready to<span className="fw-bold d-block"> manage risks? </span>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="content text-white lh-lg text-center text-md-start">
                    Our proprietary risk management strategies encompass the core of our philosophy.
                    These strategies are not just sets of rules; they’re adaptive, intelligent
                    systems that learn from market patterns and evolve with them. They’re designed
                    to identify potential risks before they become problematic, ensuring that our
                    clients are always a step ahead.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-us-content box-padding-border-bottom bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h4 className="text-center">
                Company <span>Philosophy</span>
              </h4>
            </div>
            <div className="col-12 mb-5">
              <p>
                {' '}
                The philosophies of strategy architecture, risk management strategies and portfolio
                integration are complementary.
              </p>
            </div>
            <div className="col-12 lh-lg text-white">
              We believe first and foremost that any great methodology should excel not only on its
              own but also offer quantifiable benefits to each other via an expertly constructed
              portfolio. Each of the programs has been designed from the ground up with the thought
              of such integration in mind.
              <br />
              <br />
              Many quantitative trading firms simply curve fit a single strategy to a narrow range
              of recent market conditions using parameter optimization. While this method can
              produce impressive short- term returns, the very nature of the auction process in all
              markets has proven again and again its propensity to eventually identify and eliminate
              any such inefficiencies. Where many absolute return programs end and SOVRUN begins is
              in our commitment to integration.
              <br />
              <br />
              Our strategies were not only designed to integrate with each other into a complete
              absolute return solution, but that solution is flexible enough to be custom integrated
              into the overall portfolio as well. Above all, risk management strategies are our
              primary focus. Simply stated, we expend far more effort learning to minimize losing
              periods than we do on maximizing winning ones. In our view, high returns are only
              impressive if they are markedly consistent across the time horizon for investment and
              if coupled with equally impressive drawdown mitigation during the inevitable difficult
              periods encountered by any strategy.
            </div>
          </div>
        </div>
      </section>
      <section className="about-us-content box-padding-border-bottom bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h4 className="text-center">
                Trading <span>Methodology</span>
              </h4>
            </div>
            <div className="col-12 mb-5">
              <p>
                {' '}
                The one certainty in all markets is that they must move to survive, constantly
                auctioning to find consensus of value between buyers and sellers. This motion is
                either vertical or horizontal in any timeframe at any one time.
              </p>
            </div>
            <div className="col-12 lh-lg text-white">
              No one program can be truly efficient at capturing returns and mitigating risk
              simultaneously in both directionally trending markets and in non-directional or
              rangebound markets. The trading philosophy effectively surrenders to these certainties
              with each of the core programs having been constructed to perform best in either
              trending or non-trending conditions and across a carefully chosen range of time frames
              from as much as many months to less than a minute at a time. With this, each
              successive shorter periodicity strategy is trading “inside” the longer periodicity
              strategy above it. The intention of utilizing an approach with multiple time frame
              coverage and multiple simultaneous long and short trading programs is to provide
              insulation from any surprise exposure to negative price shocks. When programs are
              combined, they may hedge one another at times, creating a sort of core position that
              is either net long, net short, or market neutral.
            </div>
          </div>
        </div>
      </section>
      <div className="brands bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 logo-border-right text-center">
              <h5>Trading Partners</h5>
              <div className="logos">
                <div className="row row-col-2">
                  <div className="circle col-6 mb-5">
                    <img src="./assets/image/logo/wedbush.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/marex.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/dorman.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/interactivebrokers.png" alt="" />
                  </div>
                </div>
                <div>
                  <div className="text-white mt-5 text-start info px-lg-5">
                    * SOVRUN.IO can route futures trades to any Futures Commission Merchant (FCM)
                    linked to CQG routing. The list above includes our preferred FCMs.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 text-center">
              <h5>Technology Partners</h5>
              <div className="logos">
                <div className="row">
                  <div className="circle col-6 mb-5">
                    <img src="./assets/image/logo/robotcapital.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/orionfinx.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/cqg.png" alt="" />
                  </div>
                  <div className="circle col-6">
                    <img src="./assets/image/logo/mosaic.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default About
