import React, { useEffect, useState } from 'react'
import Top from './Top'

function Header() {
  const [page, setPage] = useState()

  useEffect(() => {
    setPage(window.location.pathname)
  }, [])

  return (
    <>
      <Top />
      <div className="hero container-fluid" style={{ top: 0, position: 'sticky', zIndex: 99999 }}>
        <div className="container-lg container">
          <div className="row">
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 my-4">
              <div className="col-md-3 mb-2 mb-md-0">
                <a
                  href="/"
                  className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
                >
                  <img src="./assets/image/sovrun-logo.svg" alt="" />
                </a>
              </div>
              <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                <li className="nav-item">
                  <a href="/about-us" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/strategies" className="nav-link">
                    Strategies
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/videos" className="nav-link">
                    Videos
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a href="/privacy-statement" className="nav-link">
                    Privacy Statement
                  </a>
                </li> */}
                <li className="nav-item">
                  <a href="/contact" className="nav-link">
                    Contact
                  </a>
                </li>
              </ul>

              <div className="col-md-3 text-end">
                {page !== '/account' && (
                  <a href="/account" className="btn link-button">
                    Book an Appointment
                  </a>
                )}
              </div>
            </header>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
