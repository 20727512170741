/* eslint-disable no-undef */
import noUiSlider from './nouislider.min.js'

let allCheckBox = document.querySelectorAll('#switcher')
const strategyPerformance = document.querySelector('.strategy-performance')
const strategyProfile = document.querySelector('.strategy-profile')

allCheckBox.forEach((checkbox) => {
  checkbox.addEventListener('change', (event) => {
    if (event.target.checked) {
      strategyPerformance.classList.remove('d-none')
      strategyPerformance.classList.add('d-block')

      strategyProfile.classList.remove('d-block')
      strategyProfile.classList.add('d-none')
    } else {
      strategyPerformance.classList.remove('d-block')
      strategyPerformance.classList.add('d-none')

      strategyProfile.classList.remove('d-none')
      strategyProfile.classList.add('d-block')
    }
  })
})

export function ranger() {
  var nonLinearSlider = document.getElementById('nonlinear')
  if (nonLinearSlider !== null) {
    try {
      noUiSlider.create(nonLinearSlider, {
        start: 40,
        connect: 'lower',
        range: {
          min: [10000],
          '10%': [5000, 5000],
          '50%': [40000, 10000],
          max: [1000000],
        },
      })
      // noUiSlider.create(nonLinearSlider, {
      //   connect: true,
      //   // behaviour: 'tap',
      //   start: [10000, 40000],
      //   range: {
      //     min: [10000],
      //     '10%': [5000, 5000],
      //     '50%': [40000, 10000],
      //     max: [500000],
      //   },
      // })
    } catch (error) {
      //
    }

    var nodes = [
      document.getElementById('lower-value'), // 0
      document.getElementById('upper-value'), // 1
    ]

    nonLinearSlider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
      nodes[handle].innerHTML =
        '$' +
        parseFloat(values[handle]).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
    })
  }
}

export function chart(id) {
  const ctx = document.getElementById(id)

  const xValues = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'Jun',
    'July',
    'Agust',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  try {
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: xValues,
        datasets: [
          {
            data: [0, 3500, 1200, 1460, 2600, 1200, 300, 700, 2000, 5000],
            borderColor: '#f5b763',
            borderRadius: '10',
            fill: false,
            label: 'S&P',
            tension: 0.5,
          },
          {
            data: [1600, 1700, 1700, 1900, 2000, 2700, 4000, 5000, 6000, 7000],
            borderColor: '#61b8ff',
            fill: false,
            label: 'Apple',
            tension: 0.5,
          },
          {
            data: [300, 700, 2000, 5000, 6000, 4000, 2000, 1000, 200, 100],
            borderColor: '#3673e8',
            fill: false,
            label: 'Google',
            tension: 0.5,
          },
        ],
      },
      options: {
        legend: { display: false },
      },
    })
  } catch (error) {
    //
  }
}
