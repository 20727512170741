import axios from 'axios'
import { setAuthHeader } from './setAuthHeader'

const BaseURL = process.env.REACT_APP_API_URL

export const createApi = (path) => {
  const api = axios.create({
    baseURL: BaseURL,
    headers: setAuthHeader(),
  })
  return api
}
