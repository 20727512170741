import numeral from 'numeral'
import { BaseURL } from './productApiService'

export const parseDashDate = (date) => {
  let d
  if (typeof date === 'string' || date instanceof String) {
    if (date.includes('T')) {
      d = new Date(date)
    } else {
      let parts = date.split('-')
      d = new Date(parts[0], parts[1] - 1, parts[2])
    }
  } else {
    d = date
  }
  return d
}

export const currency_format_usdolar = (value) => {
  return numeral(value).format(`$0,0.00`)
}

export const current_backend_link = BaseURL

export const decimal_format = (value, digit = 0) => {
  return value === null || value === undefined
    ? ''
    : value.toLocaleString('en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      })
}
