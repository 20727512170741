/* eslint-disable prettier/prettier */
import React from 'react'
import Header from '../components/Header'
import Strategies from '../components/Strategies'
import Footer from '../components/Footer'

function Algorithmic() {
  return (
    <>
      <Header></Header>
      <section className="subbanner bg-blue d-flex align-items-center justify-content-center">
        <h1 className="text-white text-center">Strategies</h1>
      </section>

      {/* Algorithmic strategies */}
      <section className="algorithmic bg-dark">
        <div className="container">
          <div className="row">
            <Strategies />
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-9">
              <h4>DELTA S&amp;P</h4>
              <p className="text-white">
                The DELTA S&P program is a mechanical long-short program utilizing a proprietary
                trend following methodology that executes when specific criteria are met. DELTA S&P
                combines DELTA ST and LT, executing trades that utilize various time intervals to
                calculate its criteria. The DELTA ST program utilizes a unique set of mechanical
                models where maximum daily risk is finite whereas DELTA LT maximizes position risk.
                When conditions are met, the DELTA ST program executes entries during regular NYSE
                market hours. While some positions remain open past the NYSE market close, the risk
                management models remain in place minimizing the majority of overnight price shock
                risk to capital. DELTA LT looks for entry metrics to trigger within the CME GLOBEX
                trading hours and immediately places risk management once a position is established.
                The DELTA LT program utilizes the proprietary risk management strategy with many of
                our other mechanical strategy engines.
              </p>
              <p className="text-white" style={{ whiteSpace: 'pre-line' }}>
                Recommended Minimum Allocation = $50,000 \n
              </p>
              <p className="text-white" style={{ whiteSpace: 'pre-line' }}>
                Unit Margin to Equity = 50% Monthly \n
              </p>
              <p className="text-white" style={{ whiteSpace: 'pre-line' }}>
                Subscription Fee = $200
              </p>                            
              <br />
              <br />
              <img src="./assets/image/algorithmic-pic.png" className="my-5 img-fluid" alt="" />
              <p className="text-white"></p>
              <div className="col-12 mt-5">
                <div className="rounded-4 bg-purple p-5 d-flex align-items-center">
                  <img src="./assets/image/bag-of-coins.svg" alt="" />
                  <div>
                    {/* <p className="text-white">
                      All strategies at one starting price…FREE for the first 14 day!{' '}
                    </p> */}
                    <a className="text-white text-decoration-none fw-bold" href="/">
                      Subscribe <img src="./assets/image/arrow-btn.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  )
}

export default Algorithmic
