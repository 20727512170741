import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactPlayer from 'react-player/lazy'
import { current_backend_link } from '../utils/common'
import CloseButton from 'react-bootstrap/CloseButton'

function AllVideos(props) {
  const [show_modal, setShowModal] = useState(false)
  const [video_link, setVideoLink] = useState()

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const show_video_modal = (video) => {
    setVideoLink(video)
    setShowModal(true)
  }
  return (
    <>
      {props.videos.map((video, index) => {
        return (
          <div className="col-12 col-md-4" key={index}>
            <div className="video-image mb-4">
              <img
                height={374}
                width={205}
                src={current_backend_link + video.thumbnail}
                className="play-btn img-fluid"
                alt=""
                onClick={(e) => show_video_modal(video.link)}
              />
            </div>
            <div className="video-text">
              <p className="text-white">{video.description}</p>
            </div>
          </div>
        )
      })}
      <Modal show={show_modal} onHide={handleCloseModal} id="videoplayer" centered size="xl">
        <Modal.Header>
          <CloseButton onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={current_backend_link + video_link}
            width={1118}
            height={950}
            controls={true}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AllVideos
